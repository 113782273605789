import * as types from '../actions/actionTypes';

const initialState = {
    payload: {
        organizations: [],
    },
    loading: false,
    error: null,
    success: null
}

const organizations = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_ORGANIZATIONS_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_ALL_ORGANIZATIONS_SUCCESS:
            return { ...state, loading: false, success: true, payload: { organizations: action.payload } };
        case types.GET_ALL_ORGANIZATIONS_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default organizations;