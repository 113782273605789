import * as types from '../actions/actionTypes';

const initialState = {
    payload: {

        countries: [],
        countryFinInfo:[],
        countryFullInfo:[],
    },
    loading: false,
    error: null,
    success: null
}

const countries = (state = initialState, action) => {    
    switch (action.type) {
        case types.GET_COUNTRIES_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_COUNTRIES_SUCCESS:
            return { ...state, loading: false, success: true, payload: {...state.payload, countries: action.payload } };
        case types.GET_COUNTRIES_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        case types.GET_COUNTRY_FIN_INFO_REQUEST:
        case types.GET_COUNTRY_FULL_INFO_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_COUNTRY_FIN_INFO_SUCCESS: { 
            let org = Array.from(state.payload.countryFinInfo);            
            const index =  org.indexOf(x => x.countryCode === action.payload.countryCode);
            if (index > 0) {
                org[index] = action.payload;
            }
            else {
                org.push(action.payload);
            }
            return { ...state, loading: false, success: true, payload: { ...state.payload, countryFinInfo: org } };
        }
        case types.GET_COUNTRY_FULL_INFO_SUCCESS: {             
            let org = Array.from(state.payload.countryFullInfo);            
            const index =  org.indexOf(x => x.countryCode === action.payload.countryCode);
            if (index > 0) {
                org[index] = action.payload;
            }
            else {
                org.push(action.payload);
            }
            return { ...state, loading: false, success: true, payload: { ...state.payload, countryFullInfo: org } };
        }
        case types.GET_COUNTRY_FIN_INFO_FAILURE:
        case types.GET_COUNTRY_FULL_INFO_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default countries;