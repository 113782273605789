import * as types from '../actions/actionTypes';

const initialState = {
    payload: {

        organization: []
    },
    loading: false,
    error: null,
    success: null
}

const organization = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORGANIZATION_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_ORGANIZATION_SUCCESS: {            
            let org = Array.from(state.payload.organization);
            const index =  org.indexOf(x => x.shortName === action.payload.shortName);
            if (index > 0) {
                org[index] = action.payload;
            }
            else {
                org.push(action.payload);
            }
            return { ...state, loading: false, success: true, payload: { organization: org } };
        }
        case types.GET_ORGANIZATION_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default organization;