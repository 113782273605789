import * as types from '../actions/actionTypes';

const initialState = {
    payload: {
        current: [],
        predicted: {
            rate: [],
            hurstValue: 0,
            hurstRandomValue: 0,
            hurstPetersValue: 0,
            hurstPetersRandomValue: 0,
            mae: 0,
            rmse: 0
        }
    },
    loading: false,
    error: null,
    success: null
}

const rate = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_UAH_USD_RATE_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_PREDICTED_UAH_USD_RATE_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_UAH_USD_RATE_SUCCESS:
            return {
                ...state, loading: false, success: true, payload: {
                    ...state.payload, current: action.payload, predicted: {
                        popularRate: [],
                        rate: [],
                        hurstValue: 0,
                        hurstRandomValue: 0,
                        hurstPetersValue: 0,
                        hurstPetersRandomValue: 0,
                        mae: 0,
                        rmse: 0
                    }
                }
            };
        case types.GET_PREDICTED_UAH_USD_RATE_SUCCESS:
            return { ...state, loading: false, success: true, payload: { ...state.payload, predicted: action.payload } };
        case types.GET_UAH_USD_RATE_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        case types.GET_PREDICTED_UAH_USD_RATE_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default rate;