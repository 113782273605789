import React from "react";
import GridContainer from "../../components/Grid/GridContainer.js";
import { makeStyles } from "@mui/styles";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

export default function About(props) {
  const { t } = useTranslation(['translation']);
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div id="typography">
          <div className={classes.title}>
            <h2>{t('About')}</h2>
          </div>
          <GridContainer>
            <div className={classes.typo}>
              <div className={classes.note}>{t('Author')}</div>
              <p>Anton Nalyvaiko</p>
            </div>
          </GridContainer>

          <GridContainer>
            <div className={classes.typo}>
              <div className={classes.note}>Description</div>
              <div>This service is designed for reviewing current economic situation of countries. All Data is collected automatically from central Banks and statistics services.<br/>
                  To read more about service design process please visit my <a href="https://antonnalivayko1.medium.com/lists" target="blank">Blog</a>.<br/>
                  I described how the idea of this service was born in the introduction <a href="https://antonnalivayko1.medium.com/world-economic-book-6613546e106b" target="blank">article</a>.<br/>
                  The next <a href="https://antonnalivayko1.medium.com/economic-data-for-european-union-81631e1b78dd" target="blank">article</a> explains how the service collects data about the European Union.<br/>
                  And also in this <a href="https://antonnalivayko1.medium.com/the-usa-and-canada-economic-data-bf912ff8abfb" target="blank">article</a> I described how the service collects data about the USA and Canada.<br/><br/>

                  <p>You can contact me if you have questions or ideas for improvement antonnalivayko1@gmail.com.</p>
                  </div>
            </div>
          </GridContainer>
        </div>
      </div>
    </div>)
}