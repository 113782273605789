import React, { Suspense, lazy } from 'react';
import classNames from "classnames";
import Grid from '@mui/material/Grid';
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';
import styles from "../assets/jss/material-kit-react/views/components.js";

const Parallax = lazy(() => import('../components/Parallax/Parallax'));
const Header = lazy(() => import('./Header/Header'));
const Footer = lazy(() => import('../components/Footer/Footer'));
const HeaderLinks = lazy(() => import('./Header/HeaderLinks'));

const useStyles = makeStyles(styles);

function Layout(props) {
  const { t } = useTranslation(['translation']);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <>
        <Header
          brand="Wecobook"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax background="#24c0f6" opacity="0.8">
          <div className={classes.container}>
                <div className={classes.brand}>
                  <h3 className={classes.title}>World economic book</h3>
                  <h5 className={classes.subtitle}>
                    {t('Header_subtitle')}
                  </h5>
                </div>
          </div>
        </Parallax>

        <div className={classNames(classes.main)}>
          <Grid container>
            <Grid xs={1} md={1}>

            </Grid>
            <Grid xs={10} md={10}>
              {props.children}
            </Grid>
            {/* <Grid xs={1} md={2}>
              <AdComponent />
            </Grid> */}
          </Grid>
        </div>
        <Footer />
      </>
    </Suspense>
  )
};

export default Layout;