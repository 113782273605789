import React, { Suspense, lazy } from 'react';
import Layout from './components/Layout';
import About from './components/About/About';
import NotFound from './components/NotFound';
import { Routes, Route } from 'react-router-dom';
import './style.scss';
import { I18nextProvider } from 'react-i18next';
import  SEO  from './components/Seo';
import i18n from './i18n';

const Home = lazy(() => import('./components/Home'));
const CountryFullFinInfo = lazy(() => import('./components/FullFinInfo/CountryFullFinInfo'));

const App = () => (
  <>
    <SEO
      title='World economic book.'
      description='Statistic overview of economic development of countries.'
      author='Anton Nalyvaiko'
      type='article'
      keywords='GDP, Gross domestic product, Balance of Payments, BOP, CPI, Consumer Price Index, Producer Price Index, Inflation' />

    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <I18nextProvider i18n={i18n}>
          <Layout>
            <Routes >
              <Route exact path='/' element={<Home />} />
              <Route path="/countries/:code" element={<CountryFullFinInfo />} />
              <Route path='/About' element={<About />} />
              <Route path='*' element={<NotFound />} />
            </Routes >
          </Layout>
        </I18nextProvider>
      </Suspense>
    </React.StrictMode>
  </>
);
export default App;