import * as types from '../actions/actionTypes';

const initialState = {
    payload: {
        gdp: [],
        yearInflaction: [],
        gdpPercapitaPPP:[]
    },
    loading: false,
    error: null,
    success: null
}

const economicIndicators = (state = initialState, action) => {    
    switch (action.type) {
        case types.GET_ACTUAL_GDP_PPP_REQUEST:
        case types.GET_ACTUAL_GDP_REQUEST:
        case types.GET_ACTUAL_YEAR_INFLACTION_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_ACTUAL_GDP_SUCCESS: {
            return { ...state, loading: false, success: true, payload: { ...state.payload, gdp: action.payload } };
        }
        case types.GET_ACTUAL_YEAR_INFLACTION_SUCCESS: {            
            return { ...state, loading: false, success: true, payload: { ...state.payload, yearInflaction: action.payload } };
        }
        case types.GET_ACTUAL_GDP_PPP_SUCCESS: {            
            return { ...state, loading: false, success: true, payload: { ...state.payload, gdpPercapitaPPP: action.payload } };
        }
        case types.GET_ACTUAL_GDP_PPP_FAILURE:
        case types.GET_ACTUAL_GDP_FAILURE:
        case types.GET_ACTUAL_YEAR_INFLACTION_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default economicIndicators;