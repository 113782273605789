export const GET_UAH_USD_RATE_REQUEST = "GET_UAH_USD_RATE_REQUEST";
export const GET_UAH_USD_RATE_SUCCESS = "GET_UAH_USD_RATE_SUCCESS";
export const GET_UAH_USD_RATE_FAILURE = "GET_UAH_USD_RATE_FAILURE";

//predicted
export const GET_PREDICTED_UAH_USD_RATE_REQUEST = "GET_PREDICTED_UAH_USD_RATE_REQUEST";
export const GET_PREDICTED_UAH_USD_RATE_SUCCESS = "GET_PREDICTED_UAH_USD_RATE_SUCCESS";
export const GET_PREDICTED_UAH_USD_RATE_FAILURE = "GET_PREDICTED_UAH_USD_RATE_FAILURE";

//PREDICTION_METHODS
export const GET_PREDICTION_METHODS_REQUEST = "GET_PREDICTION_METHODS_REQUEST";
export const GET_PREDICTION_METHODS_SUCCESS = "GET_PPREDICTION_METHODS_SUCCESS";
export const GET_PREDICTION_METHODS_FAILURE = "GET_PREDICTION_METHODS_FAILURE";

//FINDAY
export const GET_FINDAY_REQUEST = "GET_FINDAY_REQUEST";
export const GET_FINDAY_SUCCESS = "GET_FINDAY_SUCCESS";
export const GET_FINDAY_FAILURE = "GET_FINDAY_FAILURE";

//FINDAY_RANGE
export const GET_FINDAY_RANGE_REQUEST = "GET_FINDAY_RANGE_REQUEST";
export const GET_FINDAY_RANGE_SUCCESS = "GET_FINDAY_RANGE_SUCCESS";
export const GET_FINDAY_RANGE_FAILURE = "GET_FINDAY_RANGE_FAILURE";

//ORGANIZATIONS
export const GET_ALL_ORGANIZATIONS_REQUEST = "GET_ALL_ORGANIZATIONS_REQUEST";
export const GET_ALL_ORGANIZATIONS_SUCCESS = "GET_ALL_ORGANIZATIONS_SUCCESS";
export const GET_ALL_ORGANIZATIONS_FAILURE = "GET_ALL_ORGANIZATIONS_FAILURE";

//ORGANIZATION
export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILURE = "GET_ORGANIZATION_FAILURE";

//COUNTRIES
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

//COUNTRY-Short-Fin-Info
export const GET_COUNTRY_FIN_INFO_REQUEST = "GET_COUNTRY_FIN_INFO_REQUEST";
export const GET_COUNTRY_FIN_INFO_SUCCESS = "GET_COUNTRY_FIN_INFO_SUCCESS";
export const GET_COUNTRY_FIN_INFO_FAILURE = "GET_COUNTRY_FIN_INFO_FAILURE";

//ECONOMIC Unions
export const GET_ECONOMIC_UNIONS_REQUEST = "GET_ECONOMIC_UNIONS_REQUEST";
export const GET_ECONOMIC_UNIONS_SUCCESS = "GET_ECONOMIC_UNIONS_SUCCESS";
export const GET_ECONOMIC_UNIONS_FAILURE = "GET_ECONOMIC_UNIONS_FAILURE";

//ECONOMIC-Short-Fin-Info
export const GET_ECONOMIC_UNION_FIN_INFO_REQUEST = "GET_ECONOMIC_UNION_FIN_INFO_REQUEST";
export const GET_ECONOMIC_UNION_FIN_INFO_SUCCESS = "GET_ECONOMIC_UNION_FIN_INFO_SUCCESS";
export const GET_ECONOMIC_UNION_FIN_INFO_FAILURE = "GET_ECONOMIC_UNION_FIN_INFO_FAILURE";

//Actual-GDP
export const GET_ACTUAL_GDP_REQUEST = "GET_ACTUAL_GDP_REQUEST";
export const GET_ACTUAL_GDP_SUCCESS = "GET_ACTUAL_GDP_SUCCESS";
export const GET_ACTUAL_GDP_FAILURE = "GET_ACTUAL_GDP_FAILURE";

//Actual-YEAR-inflation
export const GET_ACTUAL_YEAR_INFLACTION_REQUEST = "GET_ACTUAL_YEAR_INFLACTION_REQUEST";
export const GET_ACTUAL_YEAR_INFLACTION_SUCCESS = "GET_ACTUAL_YEAR_INFLACTION_SUCCESS";
export const GET_ACTUAL_YEAR_INFLACTION_FAILURE = "GET_ACTUAL_YEAR_INFLACTION_FAILURE";

//Actual-GDP_PPP
export const GET_ACTUAL_GDP_PPP_REQUEST = "GET_ACTUAL_GDP_PPP_REQUEST";
export const GET_ACTUAL_GDP_PPP_SUCCESS = "GET_ACTUAL_GDP_PPP_SUCCESS";
export const GET_ACTUAL_GDP_PPP_FAILURE = "GET_ACTUAL_GDP_PPP_FAILURE";


//COUNTRY-Full-Fin-Info
export const GET_COUNTRY_FULL_INFO_REQUEST = "GET_COUNTRY_FULL_INFO_REQUEST";
export const GET_COUNTRY_FULL_INFO_SUCCESS = "GET_COUNTRY_FULL_INFO_SUCCESS";
export const GET_COUNTRY_FULL_INFO_FAILURE = "GET_COUNTRY_FULL_INFO_FAILURE";


//RegionalData

//Regional-Inflation
export const GET_REGIONAL_INFLATION_REQUEST = "GET_REGIONAL_INFLATION_REQUEST";
export const GET_REGIONAL_INFLATION_SUCCESS = "GET_REGIONAL_INFLATION_SUCCESS";
export const GET_REGIONAL_INFLATION_FAILURE = "GET_REGIONAL_INFLATION_FAILURE";

//Regional-Gdp
 export const GET_REGIONAL_GDP_REQUEST = "GET_REGIONAL_GDP_REQUEST";
 export const GET_REGIONAL_GDP_SUCCESS = "GET_REGIONAL_GDP_SUCCESS";
 export const GET_REGIONAL_GDP_FAILURE = "GET_REGIONAL_GDP_FAILURE";