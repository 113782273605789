import * as types from '../actions/actionTypes';

const initialState = {
    payload: {

        economicUnions: [],
        economicUnionFinInfo:[]
    },
    loading: false,
    error: null,
    success: null
}

const economicUnions = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ECONOMIC_UNIONS_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_ECONOMIC_UNIONS_SUCCESS:
            return { ...state, loading: false, success: true, payload: {...state.payload, economicUnions: action.payload } };
        case types.GET_ECONOMIC_UNIONS_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        case types.GET_ECONOMIC_UNION_FIN_INFO_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_ECONOMIC_UNION_FIN_INFO_SUCCESS: {                        
            let org = Array.from(state.payload.economicUnionFinInfo);
            const index =  org.indexOf(x => x.shortName === action.payload.shortName);
            if (index > 0) {
                org[index] = action.payload;
            }
            else {
                org.push(action.payload);
            }
            return { ...state, loading: false, success: true, payload: { ...state.payload, economicUnionFinInfo: org } };
        }
        case types.GET_ECONOMIC_UNION_FIN_INFO_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default economicUnions;