import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ title, description,  type, author, keywords }) {
    return (
        <Helmet>
          <meta charSet="utf-8" />‍
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href='https://www.wecobook.com/' />
            <meta name="robots" content="index, follow" />
            <meta name="author" content={author} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="keywords" content={keywords} />
            <meta name="google-adsense-account" content="ca-pub-1076544393536932"></meta>
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            
            <meta name="twitter:creator" content={author} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />           
        </Helmet>
    )
}