import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import  finDay  from '../reducers/finDay'
import  rate  from '../reducers/rate'
import  predictionMethods  from '../reducers/predictionMethods'
import  organizations  from '../reducers/organizations'
import  organization  from '../reducers/organization'
import  countries  from '../reducers/countries'
import  economicUnions  from '../reducers/economicUnions'
import  economicIndicators  from '../reducers/economicIndicators'

export default function configureStore(history, initialState) {
    const reducers = {
        finDay,
        rate,
        predictionMethods,
        organizations,
        organization,
        countries,
        economicUnions,
        economicIndicators
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}