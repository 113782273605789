import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from "@mui/styles";
import styles from "../assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(styles);

const NotFound = () => {
  const { t } = useTranslation(['translation']);
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <div className={classes.title}>
          <h2>{t('Error')}</h2>
        </div>
        {t('Page_not_found')}<Link to='/'>{t('to_home_page')}</Link>?
          </div>
    </div>
  )
  }

export default NotFound;