import * as types from '../actions/actionTypes';
import moment from 'moment';

const initialState = {
    payload: {
        days: [{
            date: moment.utc("0001-01-01"),
            rate: [],
            ovdp: [],
            interventions: [],
            inflation: [],
            monetary: [],
            economicActivity: [],
            stateFinanceQuarter: [],
            stateFinanceYear: [],
            balanceOfPayments: [],
            nbuKeyPolicy: {
                kEY_PolicyRate: 0
            },
            reserve: {
                rES_OffReserveAssets: 0,
                rES_ForCurrencyAssets: 0,
                rES_Gold: 0,
                rES_IMFResPosition: 0,
                rES_OthReserveAssets: 0,
                rES_SDRs: 0,
            }
        }],
        generalInfoDays: []
    },
    loading: false,
    error: null,
    success: null
}

const finDay = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FINDAY_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_FINDAY_SUCCESS:
            {
                let day = Array.from(state.payload);
                const index = day.indexOf(x => x.orzanization === action.payload.orzanization);
                if (index > 0) {
                    day[index] = action.payload;
                }
                else {
                    day.push(action.payload);
                }
                return { ...state, loading: false, success: true, payload: { days: state.payload.days, generalInfoDays: day} };
            }
        case types.GET_FINDAY_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        case types.GET_FINDAY_RANGE_REQUEST:
            return { ...state, loading: true, success: false };
        case types.GET_FINDAY_RANGE_SUCCESS:
            return { ...state, loading: false, success: true, payload: { day: state.payload.generalInfoDays, days: action.payload } };
        case types.GET_FINDAY_RANGE_FAILURE:
            return { ...state, loading: false, success: false, error: action.error };
        default:
            return { ...state, loading: false, error: null, success: false };
    }
}

export default finDay;